import * as React from 'react';

const NoResults = () => (
  <svg width="130" height="129" viewBox="0 0 130 129" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame" clip-path="url(#clip0_1137_2336)">
      <path
        id="Vector"
        d="M0.714233 96.7589V48.4955C0.716592 44.1978 2.42379 40.0766 5.46102 37.036C8.49826 33.9955 12.6175 32.2839 16.9151 32.2768H48.799C53.0671 32.256 57.1564 30.5602 60.1865 27.5544C63.2165 24.5485 64.945 20.473 64.9999 16.2053C64.9999 7.31247 72.3303 0.232117 81.2009 0.232117H113.085C117.359 0.23198 121.46 1.92107 124.495 4.93138C127.529 7.9417 129.252 12.0293 129.286 16.3035V48.2187C129.283 52.5164 127.576 56.6376 124.539 59.6782C121.502 62.7188 117.382 64.4304 113.085 64.4375H81.0714C76.7966 64.4362 72.6948 66.1249 69.66 69.1354C66.6252 72.146 64.9035 76.2343 64.8705 80.5089V96.7276C64.8687 100.944 64.0353 105.118 62.4182 109.011C60.801 112.905 58.4319 116.441 55.4462 119.417C52.4605 122.394 48.9172 124.752 45.0188 126.358C41.1204 127.963 36.9435 128.783 32.7276 128.772C24.2389 128.766 16.0995 125.392 10.0971 119.389C4.09469 113.387 0.720146 105.248 0.714233 96.7589Z"
        fill="#F9EBFF"
      />
      <path
        id="Vector_2"
        d="M103.058 31.2768V39.9777H31.0981V16.183H87.9642V31.2768H103.058Z"
        fill="#00D2FF"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M80.3166 80.3795C79.2647 83.8294 79.5177 87.5456 81.0268 90.8214C81.7318 92.3474 82.6953 93.74 83.875 94.9375C84.3714 95.443 84.9038 95.9115 85.4684 96.3393C86.1584 96.8631 86.8934 97.3247 87.6648 97.7188C89.4874 98.6481 91.4844 99.1857 93.5273 99.2972C95.5701 99.4087 97.6137 99.0915 99.5266 98.3661C100.155 98.127 100.767 97.845 101.357 97.5223C99.1608 104.045 95.6247 107.402 95.6247 107.402H79.0759L84.2634 100.259C84.1109 100.192 83.9619 100.118 83.817 100.036L85.1563 98.25L85.4732 97.8304C85.0104 97.558 84.5633 97.26 84.1339 96.9375C83.75 96.6429 83.3839 96.3393 83.0401 96.0179L81.8748 97.5625C81.8436 97.6961 81.8436 97.8351 81.8748 97.9688L75.0266 107.42H23.6158C23.6158 107.42 31.0447 100.379 31.0447 86.8839V60.0536H103.004V72.9375C100.832 71.3237 98.2552 70.3442 95.5597 70.1077C92.8643 69.8713 90.156 70.3872 87.7363 71.5982C85.7217 72.6006 83.9676 74.057 82.6116 75.8527C82.1677 76.4309 81.7719 77.0446 81.4283 77.6875C80.9625 78.5424 80.5897 79.4449 80.3166 80.3795Z"
        fill="#00D2FF"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M103.058 76.817V86.8393C103.066 89.4487 102.775 92.0505 102.191 94.5937C101.227 95.551 100.114 96.3469 98.8968 96.9509C98.8933 96.9525 98.8898 96.9533 98.8859 96.9533C98.8821 96.9533 98.8781 96.9525 98.8746 96.9509C96.8079 97.7186 94.5808 97.9514 92.4001 97.6275C90.2193 97.3036 88.1559 96.4335 86.4016 95.0982C84.8821 93.9528 83.6335 92.4864 82.7452 90.8036C81.8987 89.2126 81.3896 87.464 81.2498 85.6673C81.11 83.8706 81.3423 82.0644 81.9325 80.3616C82.2612 79.4039 82.7019 78.4883 83.2452 77.6339C83.4417 77.3259 83.6515 77.0268 83.8748 76.7411C85.0759 75.166 86.6213 73.8864 88.3927 73C90.4663 72.2148 92.7051 71.9696 94.8995 72.2874C97.0938 72.6053 99.1716 73.4757 100.937 74.817C101.717 75.4022 102.428 76.0731 103.058 76.817Z"
        fill="#00D2FF"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M65.5579 72.3929H41.3793C40.6273 72.3929 40.0177 73.0025 40.0177 73.7545C40.0177 74.5065 40.6273 75.1161 41.3793 75.1161H65.5579C66.3099 75.1161 66.9195 74.5065 66.9195 73.7545C66.9195 73.0025 66.3099 72.3929 65.5579 72.3929Z"
        fill="#00ED64"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_6"
        d="M92.4641 86.5938H60.4685C59.7165 86.5938 59.1069 87.2034 59.1069 87.9554C59.1069 88.7074 59.7165 89.317 60.4685 89.317H92.4641C93.2161 89.317 93.8257 88.7074 93.8257 87.9554C93.8257 87.2034 93.2161 86.5938 92.4641 86.5938Z"
        fill="#00ED64"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_7"
        d="M103.058 31.2768L87.8661 34.1786L89.7322 31.2768H103.058Z"
        fill="black"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_8"
        d="M93.8213 73.7545H71.9329"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="0.89 0.89"
      />
      <path
        id="Vector_9"
        d="M87.9643 26.8616V29.5893H67.232C66.874 29.5834 66.5327 29.4368 66.282 29.1811C66.0314 28.9254 65.8915 28.5813 65.8927 28.2232C65.8929 27.862 66.0355 27.5155 66.2899 27.2589C66.4159 27.1316 66.5663 27.0308 66.7319 26.9626C66.8976 26.8943 67.0751 26.86 67.2542 26.8616H87.9643Z"
        fill="#00ED64"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_10"
        d="M61.799 28.2277H39.9061"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="0.89 0.89"
      />
      <path
        id="Vector_11"
        d="M53.4686 87.9553H40.0177"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-dasharray="0.89 0.89"
      />
      <path
        id="Vector_12"
        d="M84.2764 100.232L79.0888 107.375L74.1781 114.143C73.8351 113.985 73.5104 113.79 73.2094 113.563C71.504 112.308 71.9996 111.625 71.9996 111.625L75.0801 107.375L81.9282 97.9241C82.022 98.2902 82.3256 98.8795 83.2675 99.5938C83.4488 99.7384 83.6414 99.8682 83.8434 99.9822C83.9834 100.073 84.1278 100.156 84.2764 100.232Z"
        fill="#00ED64"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_13"
        d="M85.897 99.6965L85.3481 100.455V100.478L80.3393 107.397L75.4286 114.161C75.4044 114.188 75.3755 114.21 75.3436 114.228C75.2324 114.29 75.1092 114.328 74.9822 114.339C74.8551 114.35 74.7269 114.333 74.6068 114.29C74.5163 114.276 74.4279 114.25 74.3435 114.214L74.2009 114.156L79.1116 107.388L84.2992 100.246C84.1466 100.179 83.9976 100.104 83.8528 100.022L85.1921 98.2366C85.3033 98.3383 85.4073 98.4472 85.5042 98.5625C85.5841 98.6606 85.656 98.7652 85.7187 98.875C85.7451 98.9198 85.769 98.966 85.7902 99.0134C85.9138 99.2183 85.952 99.4635 85.897 99.6965Z"
        fill="#00ED64"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_14"
        d="M85.504 97.8169L85.1871 98.2366L83.8478 100.022C83.6458 99.9084 83.4532 99.7785 83.2719 99.6339C82.3254 98.9196 82.0219 98.3303 81.9326 97.9643C81.9014 97.8306 81.9014 97.6916 81.9326 97.558L83.0979 96.0134C83.4417 96.3348 83.8078 96.6384 84.1918 96.933C84.6127 97.2516 85.0506 97.5467 85.504 97.8169Z"
        fill="#00ED64"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_15"
        d="M87.6649 97.7188L87.6204 97.7813L86.9952 98.5982L85.6559 100.384C85.5614 100.426 85.4602 100.452 85.3569 100.46C84.9897 100.48 84.6237 100.405 84.2944 100.241C84.1407 100.173 83.9916 100.096 83.848 100.009L85.1873 98.2232L85.5042 97.8036C85.0414 97.5313 84.5943 97.2332 84.1649 96.9107C83.781 96.6161 83.4153 96.3125 83.0715 95.9911L83.875 94.933C84.3714 95.4385 84.9038 95.907 85.4685 96.3348C86.1582 96.8602 86.8932 97.3233 87.6649 97.7188Z"
        fill="#00ED64"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_16"
        d="M87.6649 97.7187C86.8934 97.3247 86.1584 96.8631 85.4685 96.3393C84.9038 95.9114 84.3714 95.4429 83.875 94.9375C82.6954 93.74 81.7318 92.3474 81.0269 90.8214C79.5177 87.5456 79.2647 83.8294 80.3167 80.3795C80.6016 79.4308 80.9896 78.5162 81.4733 77.6518C81.8168 77.0089 82.2122 76.3952 82.6561 75.817C84.0121 74.0212 85.7667 72.5649 87.7813 71.5625C85.2281 72.5384 82.9994 74.2102 81.3482 76.3884C81.0401 76.8036 80.75 77.2232 80.4911 77.6518C79.9636 78.5117 79.5307 79.4262 79.2006 80.3795C78.0042 83.7752 78.1013 87.4927 79.4731 90.8214C80.273 92.7921 81.5025 94.5597 83.0715 95.9955C83.4153 96.317 83.781 96.6205 84.1649 96.9152C84.5943 97.2376 85.0414 97.5357 85.5042 97.808C85.9836 98.1008 86.4816 98.3618 86.9952 98.5893C88.9681 99.472 91.1122 99.9078 93.2731 99.8653C95.4341 99.8229 97.5585 99.3033 99.4952 98.3438C97.5883 99.0681 95.5513 99.3866 93.5142 99.279C91.4772 99.1714 89.4849 98.64 87.6649 97.7187Z"
        fill="black"
        stroke="black"
        stroke-width="0.446429"
        stroke-miterlimit="10"
      />
      <path
        id="Vector_17"
        d="M87.6649 97.7187C86.8934 97.3247 86.1584 96.8631 85.4685 96.3393C84.9038 95.9114 84.3714 95.4429 83.875 94.9375C82.6954 93.74 81.7318 92.3474 81.0269 90.8214C79.5177 87.5456 79.2647 83.8294 80.3167 80.3795C80.6016 79.4308 80.9896 78.5162 81.4733 77.6518C81.8168 77.0089 82.2122 76.3952 82.6561 75.817C84.0121 74.0212 85.7667 72.5649 87.7813 71.5625C85.2281 72.5384 82.9994 74.2102 81.3482 76.3884C81.0401 76.8036 80.75 77.2232 80.4911 77.6518C79.9636 78.5117 79.5307 79.4262 79.2006 80.3795C78.0042 83.7752 78.1013 87.4927 79.4731 90.8214C80.273 92.7921 81.5025 94.5597 83.0715 95.9955C83.4153 96.317 83.781 96.6205 84.1649 96.9152C84.5943 97.2376 85.0414 97.5357 85.5042 97.808C85.9836 98.1008 86.4816 98.3618 86.9952 98.5893V98.5893C88.9681 99.472 91.1122 99.9078 93.2731 99.8653C95.4341 99.8229 97.5585 99.3033 99.4952 98.3438C97.5883 99.0681 95.5513 99.3866 93.5142 99.279C91.4772 99.1714 89.4849 98.64 87.6649 97.7187V97.7187Z"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_18"
        d="M87.6338 97.7857L87.013 98.6116L85.6737 100.397C85.5692 100.453 85.4531 100.484 85.3346 100.487C84.9704 100.495 84.6097 100.417 84.2813 100.259C84.1287 100.192 83.9797 100.118 83.8348 100.036L85.1741 98.25L85.4911 97.8304L86.1738 96.9375L87.6068 97.7991L87.6338 97.7857Z"
        fill="black"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_19"
        d="M103.178 73L103.058 72.9152C100.886 71.3014 98.3087 70.3218 95.6132 70.0854C92.9177 69.8489 90.2095 70.3649 87.7898 71.5759C85.7752 72.5782 84.0211 74.0346 82.6651 75.8304C82.2212 76.4086 81.8254 77.0223 81.4818 77.6652C80.9982 78.5296 80.6106 79.4442 80.3257 80.3929C79.2737 83.8428 79.5263 87.559 81.0354 90.8348C81.7404 92.3608 82.7039 93.7534 83.8836 94.9509C84.38 95.4563 84.9128 95.9248 85.4775 96.3527C86.1674 96.8765 86.9024 97.3381 87.6739 97.7321C89.4965 98.6614 91.4935 99.1991 93.5363 99.3106C95.5791 99.4221 97.6227 99.1049 99.5356 98.3795C100.164 98.1403 100.776 97.8584 101.366 97.5357C103.172 96.5493 104.749 95.1904 105.991 93.5491C107.157 92.0159 108.01 90.2677 108.5 88.4045C108.99 86.5414 109.107 84.6 108.846 82.6913C108.585 80.7826 107.95 78.9442 106.978 77.2812C106.005 75.6183 104.714 74.1634 103.178 73ZM104.772 92.5982C103.991 93.6217 103.062 94.5242 102.018 95.2768C101.055 95.9681 100.004 96.5267 98.8926 96.9375C98.8891 96.9391 98.8851 96.9399 98.8812 96.9399C98.8774 96.9399 98.8738 96.9391 98.8703 96.9375C96.8036 97.7053 94.5761 97.938 92.3954 97.6141C90.2146 97.2902 88.1512 96.4201 86.3969 95.0848C84.8774 93.9394 83.6292 92.473 82.7409 90.7902C81.8944 89.1992 81.3854 87.4507 81.2455 85.6539C81.1057 83.8572 81.3381 82.051 81.9283 80.3482C82.2569 79.3905 82.6977 78.475 83.241 77.6205C83.4374 77.3125 83.6468 77.0134 83.8701 76.7277C85.074 75.157 86.6212 73.882 88.3928 73C90.5843 71.8892 93.0441 71.4169 95.4912 71.6372C97.9382 71.8574 100.274 72.7613 102.232 74.2455C102.513 74.4598 102.781 74.692 103.04 74.9196C105.495 77.1109 107.036 80.1455 107.357 83.4208C107.678 86.696 106.755 89.9721 104.772 92.5982Z"
        fill="#00ED64"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_20"
        d="M104.772 92.5982C103.991 93.6216 103.063 94.5242 102.018 95.2767C101.055 95.9681 100.004 96.5267 98.8927 96.9375C100.11 96.3335 101.223 95.5376 102.187 94.5803C102.646 94.1354 103.069 93.6563 103.455 93.1473C105.253 90.7802 106.193 87.8721 106.12 84.9005C106.047 81.929 104.965 79.0706 103.054 76.7946C102.426 76.0431 101.717 75.3648 100.937 74.7723C99.1718 73.431 97.0941 72.5606 94.8997 72.2428C92.7054 71.9249 90.4665 72.1701 88.3929 72.9553C90.5845 71.8445 93.0442 71.3722 95.4913 71.5925C97.9384 71.8127 100.274 72.7166 102.232 74.2009C102.513 74.4151 102.781 74.6473 103.04 74.875C105.508 77.068 107.057 80.1115 107.378 83.3973C107.699 86.683 106.769 89.9688 104.772 92.5982Z"
        fill="black"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_21"
        d="M85.897 99.6964L85.3481 100.455V100.478L80.3393 107.397L75.4286 114.161C75.3884 114.201 75.1604 114.42 74.6068 114.29C74.5163 114.276 74.4279 114.25 74.3435 114.214L74.2009 114.156L79.1116 107.388L84.2992 100.246L85.5134 98.5714C85.5932 98.6695 85.6647 98.774 85.7274 98.8839C85.7538 98.9286 85.7777 98.9749 85.7989 99.0223C85.9172 99.226 85.9522 99.4674 85.897 99.6964Z"
        fill="black"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_22"
        d="M14.2142 38.5804V58.6295H115.446V38.5804H14.2142ZM107.781 49.5223C107.482 49.8228 107.127 50.0612 106.735 50.2239C106.344 50.3866 105.924 50.4704 105.5 50.4704C105.076 50.4704 104.656 50.3866 104.265 50.2239C103.873 50.0612 103.518 49.8228 103.219 49.5223C103.162 49.4676 103.108 49.4095 103.058 49.3482C102.555 48.7641 102.278 48.0187 102.278 47.2478C102.278 46.4769 102.555 45.7315 103.058 45.1473C103.108 45.0832 103.162 45.0221 103.219 44.9643C103.825 44.3618 104.645 44.0237 105.5 44.0237C106.355 44.0237 107.175 44.3618 107.781 44.9643C108.383 45.5699 108.722 46.3893 108.722 47.2433C108.722 48.0974 108.383 48.9167 107.781 49.5223Z"
        fill="white"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_23"
        d="M103.058 31.2768H87.9641V16.183L103.058 31.2768Z"
        fill="white"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_24"
        d="M107.777 49.5223L111.437 53.1831"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_25"
        d="M107.781 49.5223C107.482 49.8228 107.127 50.0612 106.735 50.2239C106.344 50.3866 105.924 50.4704 105.5 50.4704C105.076 50.4704 104.656 50.3866 104.265 50.2239C103.873 50.0612 103.518 49.8228 103.219 49.5223C103.162 49.4676 103.108 49.4095 103.058 49.3482C102.555 48.7641 102.278 48.0187 102.278 47.2478C102.278 46.4768 102.555 45.7315 103.058 45.1473C103.108 45.0832 103.162 45.0221 103.219 44.9643C103.825 44.3618 104.645 44.0237 105.5 44.0237C106.355 44.0237 107.175 44.3618 107.781 44.9643C108.383 45.5699 108.722 46.3893 108.722 47.2433C108.722 48.0974 108.383 48.9167 107.781 49.5223Z"
        fill="white"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_26"
        d="M22.0804 46.3705C25.2054 46.3705 25.2054 50.8348 28.3304 50.8348C31.4554 50.8348 31.4554 46.3705 34.5804 46.3705C37.7054 46.3705 37.7054 50.8348 40.8304 50.8348C43.9554 50.8348 43.9554 46.3705 47.0804 46.3705C50.2054 46.3705 50.2054 50.8348 53.3304 50.8348C56.4554 50.8348 56.4554 46.3705 59.5804 46.3705C62.7054 46.3705 62.7054 50.8348 65.8304 50.8348C68.9554 50.8348 68.9554 46.3705 72.0804 46.3705C75.2054 46.3705 75.2054 50.8348 78.3304 50.8348C81.4554 50.8348 81.4554 46.3705 84.5804 46.3705C87.7054 46.3705 87.7054 50.8348 90.8304 50.8348"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_27"
        d="M103.062 58.6295H31.0981V63.1562H103.062V58.6295Z"
        fill="black"
        stroke="black"
        stroke-width="0.446429"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1137_2336">
        <rect width="128.571" height="128.571" fill="white" transform="translate(0.714233 0.232117)" />
      </clipPath>
    </defs>
  </svg>
);

export default NoResults;
